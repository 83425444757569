<!--  -->
<template>
  <div
    class="
      home
      page-template
      page-template-template-home
      page-template-template-home-php
      page
      page-id-11
      wpb-js-composer
      js-comp-ver-6.4.2
      vc_responsive
    "
  >
    <Headers></Headers>
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="main" class="site-main" role="main">
          <div class="content_container newlist">
            <!-- 头部banner图 -->
            <div class="section_banner_container" style="margin-bottom: 20px">
              <div class="section_banner">
                <div class="container">
                  <div class="row">
                    <div class="col-xs-12 col-sm-11">
                      <div class="section_banner_content">
                        <p class="section_banner_heading">{{ title }}</p>
                        <!-- <h1>{{ title }}</h1> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 新闻列表 -->
            <div class="container">
              <div class="row">
                <div class="col-xs-12 col-sm-10 col-sm-offset-1">
                  <article
                    id="post-75"
                    class="post-75 page type-page status-publish hentry"
                  >
                    <div class="entry-content">
                      <div class="vc_row wpb_row vc_row-fluid">
                        <div
                          class="wpb_column vc_column_container vc_col-sm-12"
                        >
                          <div class="vc_column-inner">
                            <div class="wpb_wrapper">
                              <div
                                class="
                                  wpb_text_column wpb_content_element
                                  add_outdent
                                "
                              >
                                <div class="wpb_wrapper">
                                  <div
                                    class="dirHeader"
                                    v-if="title == 'Press Releases'"
                                  >
                                    <div class="title">
                                      <strong>{{ title }}</strong>
                                    </div>
                                    <div class="span"></div>
                                  </div>
                                  <h3
                                    v-else
                                    style="
                                      font-size: 24px;
                                      font-family: 微软雅黑;
                                      font-weight: 700;
                                      color: #000;
                                    "
                                  >
                                    {{ title }}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- .entry-content -->
                  </article>
                  <!-- #post-75 -->

                  <div class="news_container">
                    <!-- <h3>2020</h3> -->
                    <div
                      class="newItem"
                      v-for="(item, index) in newList"
                      :key="index"
                    >
                      <div class="row">
                        <div
                          class="col-xs-12 col-sm-4"
                          v-if="item.cover != null"
                        >
                          <a class="newListImg"
                            ><img :src="$imgurl + item.cover" alt=""
                          /></a>
                        </div>
                        <div class="col-xs-12 col-sm-8">
                          <div id="m_news_info66" class="m_news_info">
                            <div class="news_title">
                              <router-link
                                :to="{
                                  path: '/newContent',
                                  query: { id: item.id },
                                }"
                                target="_blank"
                                :title="item.title"
                                class="article_title"
                              >
                                <span class="title_content">
                                  {{ item.title }}
                                </span>
                              </router-link>
                              <!-- <a
                                href="#"
                                target="_blank"
                                :title="item.title"
                                class="article_title"
                              >
                                <span class="title_content">
                                  {{ item.title }}
                                </span></a
                              > -->
                            </div>
                            <div
                              rowindex="0"
                              index="0"
                              class="news_time"
                              v-if="item.cover == null"
                            >
                              <span class="normal_time">
                                release time: {{ item.createTime | formatDate }}
                              </span>
                            </div>
                            <div rowindex="0" index="0" class="news_summary">
                              {{ item.intro }}
                            </div>
                            <div
                              rowindex="0"
                              index="0"
                              class="news_time"
                              v-if="item.cover != null"
                            >
                              <span class="normal_time">
                                {{ item.createTime | formatDate }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 加载按钮 -->
            <LoadMore :paging="paging" @current="jumpPage"></LoadMore>
          </div>
        </main>
      </div>
    </div>
    <!-- #content -->
    <Footers></Footers>
  </div>
</template>

<script>
import Headers from "../../components/header/header.vue";
import Footers from "../../components/footer/footer.vue";
import LoadMore from "../../components/loadMore/loadMore.vue";
import { newList } from "../../util/api.js";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { Headers, Footers, LoadMore },
  metaInfo() {
    return {
      title: "企业新闻_盈喜集团 - 盈喜集团股份有限公司|ATXG",
      meta: [
        {
          name: "keywords",
          content: " 盈喜集团新闻，盈喜动态报道，ATXG",
        },
        {
          name: "description",
          content:
            "盈喜集团新闻资讯，访问盈喜集团官网，第一时间获得关于盈喜集团最新报道和信息发布平台。",
        },
      ],
    };
  },
  data() {
    //这里存放数据
    return {
      // 页面标题
      title: "",
      // 分页数据
      paging: {
        current: 0,
        size: 0,
        total: 0,
      },
      // 新闻列表数据
      newList: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 初始化新闻列表
    async init() {
      // console.log(this.$route.params.code);
      // this.title = this.$route.params.tableTitle;
      this.title = "行业动态";
      const { data: res } = await this.$http.get(newList, {
        // params: { type: this.$route.params.title },
        params: { type: "hydt" },
      });
      this.newList = res.result.records;
      this.paging.current = res.result.current;
      this.paging.size = res.result.size;
      this.paging.total = res.result.total;
      console.log(this.newList);
    },
    // 跳页请求
    jumpPage(page) {
      console.log(page);
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.init();
    // console.log(this.$route.query)
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  // 监听地址变化
  watch: {
    $route(to, from) {
      // console.log(to.query.id, this.pageId)
      // console.log(to.query.id.indexOf(this.pageId))
      // if (to.query.id.indexOf(this.pageId) == -1) {
      this.init();
      // }
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  filters: {
    // 时间格式处理
    formatDate(time) {
      return time.split(" ")[0];
    },
  },
};
</script>
<style lang='less' scoped>
.dirHeader {
  // text-align: center;
  display: flex;
  flex-direction: column;
  // align-items: center;
  border-bottom: 1px solid #444;
  position: relative;
  padding-bottom: 20px;
  .title {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 32px;
    color: #3082c0;
  }
  .span {
    z-index: 1;
    width: 199px;
    height: 10px;
    background-color: rgb(4, 107, 186);
    position: absolute;
    top: 50px;
  }
}
// @import url(''); //引入公共css类
.newlist {
  text-align: left;
  .section_banner {
    background-image: url(../../../public/newList/banner.png);
  }
  .newSynopsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.newListImg img {
  width: 100%;
  height: 221px;
  border-radius: 5px;
  margin-bottom: 10px;
  object-fit: cover;
}

.title_content {
  font-size: 16px;
  color: #333;
}

.news_summary {
  -webkit-line-clamp: 1;
  line-height: 26px;
  color: #878787;
  white-space: normal;
  margin-top: 14px;
  font-size: 14px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.newItem {
  margin-bottom: 30px;
}

.news_time {
  line-height: 1.5;
  color: #999;
  display: inline-block;
  font-size: 14px;
  margin-right: 10px;
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
}
</style>