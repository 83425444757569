<template>
  <div class="refresh-moudle">
    <div class="loadMore">
      <div class="pc-loadMore">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="paging.current"
          layout=" prev, pager, next"
          :total="paging.total"
          :page-size="paging.size"
        >
        </el-pagination>
      </div>
      <div class="mobile-loadMore">
        <p @click="mobMore">{{ mobtext }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['paging'],
  data () {
    return {
      mobtext: '点击加载更多'
    }
  },
  created () {
    // console.log(this.paging)
  },
  methods: {
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
      // this.$emit("handleSizeChange",val)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.$emit("current", val)
    },
    // 移动端下获取更多
    mobMore () {
      this.mobtext = '正在加载中......'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='less' scoped>
.loadMore {
  width: 100%;
  text-align: center;

  .el-pagination /deep/ ul {
    padding-left: 0 !important;
  }

  .mobile-loadMore {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .pc-loadMore {
    display: none;
  }
  .mobile-loadMore {
    display: block !important;
  }
}
</style>
